const getFormatedText = (text: string, count: number) => {
    if (text !== undefined) {
        const numberShowText: number = count / 2
        if (text.length <= count) {
            return text
        }
        return `${text.slice(0, numberShowText - 3)}...${text.slice(-numberShowText, -1)}`
    }

}

export default getFormatedText