import React, {  useEffect, useRef } from 'react';
import { Title, Modal, Header, Body, GlobalStyle, CloseIconWrapper, Overlay } from './styles';
import { IModalProps } from './interfaces';
import { Dismiss24Regular } from '@fluentui/react-icons';

/**
 * Обертка для всех модалок.
 * @param modalStatus Состояние модалки, управляемое извне.
 * @param title Название модалки.
 */
const Modals: React.FC<IModalProps> = ({ modalOptions, topColor, closeModal, title, ...props }) => {
    const overlayRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (overlayRef.current?.getBoundingClientRect()) {
            const { x, y } = overlayRef.current?.getBoundingClientRect();
            overlayRef.current.style.left = `${-x}px`;
            overlayRef.current.style.top = `${-y}px`;
        }
    }, [overlayRef, modalOptions.show]);

    useEffect(() => {
        function listener(e: globalThis.KeyboardEvent) {
            if (e.code === 'Escape') closeModal();
        }
        document.addEventListener('keydown', listener);
        return () => document.removeEventListener('keydown', listener);
    }, []);

    const onModalClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    if (!modalOptions.show) return null;

    return (
        <Overlay onClick={closeModal} isModalOpen={modalOptions.show} ref={overlayRef}>
            <GlobalStyle isModalOpen={modalOptions.show} />
            <Modal topColor={topColor} onClick={onModalClick}>
                <Header>
                    <Title>{title}</Title>
                    <CloseIconWrapper onClick={closeModal}>
                        <Dismiss24Regular />
                    </CloseIconWrapper>
                </Header>
                <Body>{props.children}</Body>
            </Modal>
        </Overlay>
    );
};

export default Modals;
