import { useGeneralStore } from '../store';
import { UrlsByServices } from '../types';
import { postRequest } from '../tools/request';
import { useMutation } from '@tanstack/react-query';
import { TConnectorReq } from '../types/connectors';
import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { queryClient } from '../index';
import { GENERAL } from '../constants';

export const usePostCreateConnector = () => {
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const { token, services, selectedLocationId, messageList, newConnector } = getGeneralStore();

    const getUrl = (_services: UrlsByServices, connectorType: string): string => {
        return `${_services['fsf/metric-storage'].MANAGE_CONNECTORS}${connectorType}/`;
    };
    const mutationFn = (dataConnector: TConnectorReq) =>
        postRequest({
            url: getUrl(services, dataConnector.connector_type),
            token: token,
            data: dataConnector,
        });
    const { data, isPending, mutate, status } = useMutation<TConnectorReq, unknown, TConnectorReq, any>({
        onSuccess: (_data) => {
            updateGeneralStore({
                newConnector: null
            });
            queryClient.invalidateQueries({ queryKey: [GENERAL, 'connectors'] });

        },
        mutationFn,
        onError: (error) => {
            const myError = error as AxiosError;
            updateGeneralStore({
                messageList: [
                    ...messageList,
                    {
                        text: myError.message,
                        title: myError.name,
                        uuid: uuidv4()
                    }
                ]
            })
        },
    });

    return { isPending, mutate, data, status };
}