import { useGeneralStore } from '../store';
import { UrlsByServices } from '../types';
import { ILoadTenantData } from '../components/Connectors/interfaces';
import { postRequest } from '../tools/request';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { TConnectorReq } from '../types/connectors';

export const usePostGetDataTenantForConnector = (typeProcesses: 'new' | 'edit') => {
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const { token, services, selectedLocationId, messageList, newConnector, selectedConnectorDetail } = getGeneralStore();

    const getUrl = (_services: UrlsByServices): string => {
        return `${_services['fsf/metric-storage'].LOAD_TENANT_DATA}`;
    };
    const mutationFn = (dataConnector: ILoadTenantData) =>
        postRequest({
            url: getUrl(services),
            token: token,
            data: dataConnector,
        });
    const { data, isPending, mutate, status } = useMutation<TConnectorReq, unknown, ILoadTenantData, any>({
        onSuccess: (_data) => {
            // queryClient.invalidateQueries({ queryKey: [GENERAL, 'connectors'] });
            if (typeProcesses === 'new') {
                updateGeneralStore({
                    newConnector: {
                        ...newConnector,
                        ..._data
                    }
                });
            }
            else {
                updateGeneralStore({
                    selectedConnectorDetail: {
                        ...selectedConnectorDetail,
                        ..._data
                    }
                });
            }

        },
        mutationFn,
        onError: (error) => {
            const myError = error as AxiosError;
            updateGeneralStore({
                messageList: [
                    ...messageList,
                    {
                        text: myError.message,
                        title: myError.name,
                        uuid: uuidv4()
                    }
                ]
            })
        },
    });

    return { isPending, mutate, data, status };

}