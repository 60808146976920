import { DivWrapperInput } from '../../styles';
import { Label, Input, useId, InputOnChangeData } from '@fluentui/react-components';
import { FC, ChangeEvent } from 'react';
import { IPropsInputText } from '../../interfaces';


export const InputText: FC<IPropsInputText> = (props) => {
    const inputId = useId("input");
    const {
        label,
        value,
        type,
        required= false,
        keyName = '',
        onChange
    } = props



    const onChangeInput = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        onChange(keyName, data.value === '' ? null : data.value)
    }

    return (
        <DivWrapperInput>
            <Label required={required} htmlFor={inputId}>
                {label}
            </Label>
            <Input onChange={onChangeInput} type={type} id={inputId} value={value ? value : ''} />
        </DivWrapperInput>
    );
};