import { ALL_LOCATIONS, GENERAL, LOCATIONS } from '../constants';
import { useGeneralStore } from '../store';
import { IErrorMessage, IMessage, TLocations, UrlsByServices } from '../types';
import { getRequest } from '../tools/request';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useAddGlobalSpinner } from '../hooks';
import { v4 as uuidv4 } from 'uuid';

export const useGetAllLocations = () => {
    const queryKey = [GENERAL, ALL_LOCATIONS];
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const { currentData, token, services, messageList } = getGeneralStore();
    const { id } = currentData;
    const select = (response: TLocations) => response;

    const getUrl = (_services: UrlsByServices): string => {
        return `${_services['core/admin-service'].PROJECT_LOCATIONS_URL}`;
    };
    const queryFn = () =>
        getRequest({
            url: getUrl(services),
            token: token,
            onError: (error) => {
                updateGeneralStore({
                    messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4(),
                        },
                    ],
                });
            },
        });
    const enabled = Boolean(token) && Boolean(id);

    const { data, isFetching, error, status } = useQuery<TLocations, IErrorMessage | any, TLocations, any>({
        queryKey,
        queryFn,
        select,
        enabled,
    });

    useEffect(() => {
        if (data) {
            updateGeneralStore({ allLocations: data });
        }
    }, [data, updateGeneralStore, error]);
    useAddGlobalSpinner({ isFetching, text: 'Loading all locations' });
};
