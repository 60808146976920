import { IConnectorDataByConnectorId, TConnectorParams } from '../interfaces';

const getConnectorList = (objConnectors: IConnectorDataByConnectorId) => {
    let connectorsList: TConnectorParams = []
    Object.keys(objConnectors).forEach(key => {
        connectorsList.push({
            name:key,
            value:key,
        })
    })
    return connectorsList
}

export default getConnectorList;