import { useGeneralStore } from '../store';
import { UrlsByServices } from '../types';
import { patchRequest } from '../tools/request';
import { useMutation } from '@tanstack/react-query';

import { TConnectorReq } from '../types/connectors';
import { queryClient } from 'src';
import {  GENERAL } from 'src/constants';
import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const usePatchEditConnector = () => {
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const { token, services, messageList } = getGeneralStore();

    const getUrl = (_services: UrlsByServices, dataConnector: TConnectorReq): string => {
        return `${_services['fsf/metric-storage'].MANAGE_CONNECTORS}${dataConnector?.connector_type}/${dataConnector?.id}/`;
    };
    const mutationFn = (dataConnector: TConnectorReq) =>
        patchRequest({
            url: getUrl(services, dataConnector),
            token: token,
            data: dataConnector,
        });
    const { data, isPending, mutate, status } = useMutation<TConnectorReq, unknown, TConnectorReq, any>({
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [GENERAL, 'connectors'] });
            updateGeneralStore({ selectedConnector: null });
        },
        mutationFn,
        onError: (error) => {
            const myError = error as AxiosError;
            updateGeneralStore({
                messageList: [
                    ...messageList,
                    {
                        text: myError.message,
                        title: myError.name,
                        uuid: uuidv4()
                    }
                ]
            })
        },
    });

    return { isPending, mutate, data, status };
};
