import { useAddGlobalSpinner } from '../hooks';
import { GENERAL, PROJECTS } from '../constants';
import { useGeneralStore } from '../store';
import { IProjects, IProjectsById } from '../types';
import { getRequest } from '../tools/request';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useGetProjects = () => {
    const queryKey = [GENERAL, PROJECTS];
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const { currentData, token, services, messageList } = getGeneralStore();
    const { id } = currentData;
    const select = (response: IProjects) => response;
    const queryFn = () =>
        getRequest({
            url: services['core/admin-service'].PROJECTS_URL,
            token: token,
            onError: (error) => {
                updateGeneralStore({ messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4()
                        }
                    ] });
            }
        });
    const enabled = Boolean(token) && Boolean(id);
    const { data, isFetching, error } = useQuery<IProjects, unknown, IProjects, any>({
        queryKey,
        queryFn,
        select,
        enabled,
    });

    useEffect(() => {
        if (!data) {
            return;
        }
        if (Object.values(data).length > 0) {
            updateGeneralStore({ projects: data });

            let obj:IProjectsById  = {}
            data.forEach((item, index) => {
                obj[item.id] = item;
            })
            updateGeneralStore({ projectsById: obj });
        }
    }, [data, updateGeneralStore]);

    useAddGlobalSpinner({ isFetching, text: 'Loading projects' });
};
