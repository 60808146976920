import { Label, Option, PopoverProps } from '@fluentui/react-components';
import { CustomPopover } from '../../../CustomPopover/CustomPopover';
import {
    DivBoxSearchSC,
    DivWrapperDropdownAndLabelSC,
    DivWrapperListPopoverSC,
    DivWrapperListPopoverScrollSC, DivWrapperSelectConnectorTypeSC,
} from '../../styles';
import { Search } from '../../../Search/Search';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPropsSelectConnectorType, TConnectorParams } from '../../interfaces';
import { useGeneralStore } from '../../../../store';
import getConnectorList from '../../settings/getConnectorList';
import connectorDataByTypeConnector from '../../settings/connectorDataByTypeConnector';
import { useGetConnectorDetail } from '../../../../api/useGetConnectorDetail';

export const SelectConnectorType:FC<IPropsSelectConnectorType> = ({onChangeStatusLoad}) => {
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    const [dataConnectorParams, setDataConnectorParams] = useState<TConnectorParams>([{
        name: '',
        value: ''
    }]);
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const [selectedOptions, setSelectedOptions] = useState<string>('');
    const [labelsList, setListLabels] = useState<TConnectorParams>([]);
    const {
        newConnector,
        selectedTenant,
        connectorDependenciesByConnectorType
    } = getGeneralStore()


    useEffect(() => {
        setDataConnectorParams(getConnectorList(connectorDataByTypeConnector))
        setListLabels(getConnectorList(connectorDataByTypeConnector))
    }, []);

    const handleOpenChange: PopoverProps["onOpenChange"] = (e, _data) => {
        setOpen(_data.open || false);
        // setDataConnectorParams(labelsList ? labelsList : dataConnectorParams)
    }

    const onSearchChange = (value: string) => {
        const filteredList = labelsList?.filter((item, i) =>{
            return item.name.toLowerCase().includes(value.toLowerCase())
        })
        setDataConnectorParams(filteredList ? filteredList : [])
    };

    const onSelectOption = (value: string) => () => {
        setSelectedOptions(value ? value : '')
        if (connectorDependenciesByConnectorType) {
            updateGeneralStore({
                newConnector: {
                    ...connectorDataByTypeConnector[value],
                    tenant: selectedTenant ? selectedTenant.id : 0,
                }
            })
        }
        setOpen(false);
    }



    return (
        <DivWrapperSelectConnectorTypeSC>
            <DivWrapperDropdownAndLabelSC>
                <Label >{t('Connector type')}</Label>
                <CustomPopover
                    open={open}
                    onOpenChange={handleOpenChange}
                    label={newConnector ? newConnector.connector_type : ''}
                >
                    <DivWrapperListPopoverSC>
                        <DivBoxSearchSC>
                            <Search
                                placeholder={`${t('Search type connector')}`}
                                handleChange={onSearchChange}
                            />
                        </DivBoxSearchSC>
                        <DivWrapperListPopoverScrollSC>
                            {dataConnectorParams.map((option, i) => (

                                <Option onClick={onSelectOption(option.value)} value={option.value}
                                        key={`dropdown-new-connector-${i}`}>
                                    {option.name}
                                </Option>
                            ))}
                        </DivWrapperListPopoverScrollSC>

                    </DivWrapperListPopoverSC>
                </CustomPopover>

            </DivWrapperDropdownAndLabelSC>
        </DivWrapperSelectConnectorTypeSC>

    );
};