import { IItemListInputs } from '../interfaces';

export const inputsListBase:IItemListInputs [] = [
    // {
    //     type: 'checkbox',
    //     label: 'Is active',
    //     keyName: 'is_active',
    // },
    {
        type: 'date',
        label: 'Date from',
        keyName: 'date_from',
    },
    {
        type: 'date',
        label: 'Date to',
        keyName: 'date_to',
    },
    {
        type: 'timesList',
        label: 'Load time utc',
        keyName: 'load_time_utc',
    },


]

export const inputsListBase2:IItemListInputs [] = [

    {
        type: 'text',
        label: 'Legal entity',
        keyName: 'legal_entity',
    },
    {
        type: 'text',
        label: 'Tenant inn',
        keyName: 'tenant_inn',
    },
    {
        type: 'text',
        label: 'Tenant kpp',
        keyName: 'tenant_kpp',
    },
    {
        type: 'text',
        label: 'Tenant ogrn',
        keyName: 'tenant_ogrn',
    },
    {
        type: 'text',
        label: 'Tenant bik',
        keyName: 'tenant_bik',
    },
    {
        type: 'text',
        label: 'Tenant agreement number',
        keyName: 'tenant_agreement_number',
    },
    {
        type: 'text',
        label: 'Tenant address',
        keyName: 'tenant_address',
    },
    {
        type: 'text',
        label: 'Comment',
        keyName: 'comment',
    },


    // {
    //     type: 'text',
    //     label: 'Proxy',
    //     keyName: 'proxy',
    // }

]