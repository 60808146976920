import { BaseInputsFirstLine } from './BaseInputs';
import React, { FC, useEffect, useState } from 'react';
import { IPropsModalContentConnector, IValidateData } from '../interfaces';
import { useGetConnector } from '../../../api/useGetConnector';
import { CustomSpinner } from '../../Spinner/Spinner';
import { CustomInputs } from './CustomInputs';
import {
    DivSpinnerBox2SC,
    DivWrapper2ButtonSC,
    DivWrapperInputListsSC,
} from '../styles';
import { Button, Divider, Spinner } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import DisplayList from './customComponents/DisplayList';
import { useGetPointsOfSale } from '../../../api/useGetPointsOfSale';
import { useGeneralStore } from '../../../store';
import { usePatchEditConnector } from '../../../api/usePatchEditConnector';
import { BaseInputsSecondLine } from './BaseInputs2';
import { inputsListBase, inputsListBase2 } from '../settings/inputsList';
import { inputsCustomObj } from '../settings/inputsCustomList';
import { usePostValidateConnectorData } from '../../../api/usePostValidateConnectorData';
import { v4 as uuidv4 } from 'uuid';

export const EditConnectorModal: FC<IPropsModalContentConnector> = (props) => {
    const { connectorId, connectorType = '' } = props;
    const { t } = useTranslation();
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const { selectedConnectorDetail, selectedLocationId, messageList, connectorDependenciesByConnectorType } = getGeneralStore();
    const { isFetching, refetch: refetchConnector } = useGetConnector(connectorId, connectorType);
    const { isFetching: isFetching2, data } = useGetPointsOfSale({
        dateFrom: selectedConnectorDetail?.date_from ? selectedConnectorDetail?.date_from : '',
        dateTo: selectedConnectorDetail?.date_to ? selectedConnectorDetail?.date_to : '',
        limit: 10000,
        offset: 0,
        PLId: selectedLocationId ? selectedLocationId : 0,
        tenantId: selectedConnectorDetail?.tenant ? selectedConnectorDetail?.tenant : 0,
        connectorId: selectedConnectorDetail?.id ? selectedConnectorDetail?.id : 0,
    });
    const { isPending, mutate } = usePatchEditConnector();
    const {
        isPending: isPendingValidConnector,
        mutate: mutateValidConnector,
        data: dataValidConnector,
    } = usePostValidateConnectorData()

    useEffect(() => {
        refetchConnector();
    }, []);

    useEffect(() => {
        if (dataValidConnector && !dataValidConnector.is_valid) {
            updateGeneralStore({
                messageList: [
                    ...messageList,
                    {
                        title: t('Validation'),
                        text: t('Not valid'),
                        uuid: uuidv4()
                    }
                ]
            })
        }
        setIsDisabled(dataValidConnector ? !dataValidConnector.is_valid : true)

    }, [dataValidConnector]);

    useEffect(() => {
        setIsDisabled(true)
    }, [selectedConnectorDetail]);



    const onClick = () => {
        if (selectedConnectorDetail) {
            mutate(selectedConnectorDetail);
        }

    };
    const onChange = (keyName: string, value: string | number | boolean | null | number[]) => {

        if (selectedConnectorDetail) {
            updateGeneralStore({
                selectedConnectorDetail: {
                    ...selectedConnectorDetail,
                    [keyName]: value
                }
            })
        }

    }

    const onClickValidate = () => {
        if (selectedConnectorDetail) {
            let obj:IValidateData = {
                connector_type: selectedConnectorDetail.connector_type,
                form_fields: {}
            }
            Object.keys(selectedConnectorDetail).forEach((item, i) => {
                if(inputsCustomObj[item]) {
                    // @ts-ignore
                    obj.form_fields[item] = selectedConnectorDetail[item]
                }
            })
            mutateValidConnector(obj)
        }

    }


    return (
        <DivWrapperInputListsSC>
            <BaseInputsFirstLine
                inputListBase={inputsListBase}
                connectorDetail={selectedConnectorDetail}
                onChange={onChange}
            />
            <Divider />

            <CustomInputs
                connectorDetail={selectedConnectorDetail}
                inputList={inputsCustomObj}
                onChange={onChange}
                connectorDependenciesByConnectorType={connectorDependenciesByConnectorType}
                typeProcesses={'edit'}
            />
            <Divider />
            <BaseInputsSecondLine
                inputListBase={inputsListBase2}
                connectorDetail={selectedConnectorDetail}
                onChange={onChange}
            />
            <Divider />
            <DisplayList list={data ? data.results : []} />
            <CustomSpinner text={'get connector'} isShow={isFetching} />
            <CustomSpinner text={'get points of sale'} isShow={isFetching2} />
            <DivWrapper2ButtonSC>
                <Button
                    appearance="primary"
                    onClick={onClickValidate}
                    icon={isPendingValidConnector ? <DivSpinnerBox2SC>
                        <Spinner size={'extra-tiny'} />
                    </DivSpinnerBox2SC> : null}
                >
                    {t('Validate')}
                </Button>
                <Button
                    appearance="primary"
                    onClick={onClick}
                    disabled={isDisabled}
                    icon={isPending ? <DivSpinnerBox2SC>
                        <Spinner size={'extra-tiny'} />
                    </DivSpinnerBox2SC> : null}
                >
                    {t('Edit')}
                </Button>
            </DivWrapper2ButtonSC>
        </DivWrapperInputListsSC>
    );
};
