import React, { FC, useEffect, useState } from 'react';
import { Label, PopoverProps } from '@fluentui/react-components';
import { CustomPopover } from '../../../CustomPopover/CustomPopover';
import {
    DivItemCheckboxSC,
    DivWrapperCheckBoxListSC,
    DivWrapperListPopoverForListTimesSC,
    DivWrapperListSC,
} from '../../styles';
import { IPropsCheckboxList } from '../../interfaces';
import { Checkbox } from "@fluentui/react-components";
import getTimes from '../../settings/times';

const CheckboxList:FC<IPropsCheckboxList> = (props) => {
    const {
        label,
        keyName,
        values,
        onChange,
        connectorDetail
    } = props

    const [open, setOpen] = useState<boolean>(false);
    const [strValue, setStrValue] = useState<string>('');

    useEffect(() => {
        if (values) {
            setStrValue(values.join(','))
        }

    }, [values]);

    const handleOpenChange: PopoverProps["onOpenChange"] = (e, _data) => {
        setOpen(_data.open || false);

    }

    const onChangeTimes = (time: number) => () => {
        if (connectorDetail) {

            let temp: number[] = [
                // @ts-ignore
                ...connectorDetail[keyName]
            ]

            // @ts-ignore
            if (temp.indexOf(time) === -1) {
                temp = [
                    ...temp,
                    time
                ]
            }
            else {
                temp.splice(temp.indexOf(time), 1)
            }


            temp.sort((a, b) => {
                if (a > b) {
                    return 1
                }
                else if (a < b) {
                    return -1
                }
                return 0
            })

            onChange(keyName, temp)

        }
    }

    return (
        <DivWrapperCheckBoxListSC>
            <Label >{label}</Label>
            <CustomPopover
                open={open}
                onOpenChange={handleOpenChange}
                label={strValue}
            >
                <DivWrapperListPopoverForListTimesSC>
                    <DivWrapperListSC>
                        {
                            values &&  getTimes().map((item, i) => {

                                return(
                                    <DivItemCheckboxSC key={`listTimes-${i}`}>
                                        <Checkbox
                                            checked={values.includes(item)}
                                            onChange={onChangeTimes(item)}
                                            label={item}
                                        />
                                    </DivItemCheckboxSC>
                                )
                            })
                        }

                    </DivWrapperListSC>

                </DivWrapperListPopoverForListTimesSC>
            </CustomPopover>
        </DivWrapperCheckBoxListSC>
    );
}

export default CheckboxList;