import { useGeneralStore } from '../store';
import { UrlsByServices } from '../types';
import {  postRequest } from '../tools/request';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { IValidateData, IValidateDataConnector } from '../components/Connectors/interfaces';

export const usePostValidateConnectorData = () => {
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const { token, services, messageList } = getGeneralStore();

    const getUrl = (_services: UrlsByServices): string => {
        return `${_services['fsf/metric-storage'].VALIDATE_TENANT_DATA}`;
    };
    const mutationFn = (dataConnector: IValidateData) =>
        postRequest({
            url: getUrl(services),
            token: token,
            data: dataConnector,
        });
    const { data, isPending, mutate, status } = useMutation<IValidateDataConnector, unknown, IValidateData, any>({
        onSuccess: () => {
            // queryClient.invalidateQueries({ queryKey: [GENERAL, 'connectors'] });
        },
        mutationFn,
        onError: (error) => {
            const myError = error as AxiosError;
            updateGeneralStore({
                messageList: [
                    ...messageList,
                    {
                        text: myError.message,
                        title: myError.name,
                        uuid: uuidv4()
                    }
                ]
            })
        },
    });

    return { isPending, mutate, data, status };

}