import { Checkbox } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { IPropsInputCheckbox } from '../../interfaces';
import { DivWrapperCheckboxInner, DivWrapperCheckboxSC } from '../../styles';

export const InputCheckBox: FC<IPropsInputCheckbox> = (props) => {
    const { t } = useTranslation();

    const {
        label,
        checked,
        keyName,
        onChange
    } = props


    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(keyName, event.target.checked)
    }

    return (
        <DivWrapperCheckboxSC>
            <DivWrapperCheckboxInner>
                <Checkbox
                    checked={checked}
                    onChange={onChangeInput}
                    label={t(label)}
                />
            </DivWrapperCheckboxInner>

        </DivWrapperCheckboxSC>
    );
};