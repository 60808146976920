import { Label } from '@fluentui/react-components';
import { FixedSizeList } from 'react-window';
import { List, ListItem } from '@fluentui/react-list-preview';
import React, { FC } from 'react';
import { IPropsBlockListText } from '../../interfaces';
import { CustomLabelSC } from '../../styles';

const CountriesList = React.forwardRef<HTMLUListElement>(
    (props: React.ComponentProps<typeof List>, ref) => (
        <List navigationMode="items" aria-label="Countries" {...props} ref={ref} />
    )
);

export const BlockListText:FC<IPropsBlockListText> = (props) => {

    const {
        label,
        data,
        labelColor = ''
    } = props


    return (
        <div>
            <CustomLabelSC size="large" colorText={labelColor}>
                {label}
            </CustomLabelSC>
            <FixedSizeList
                height={50}
                itemCount={data.length}
                itemSize={20}
                width="100%"
                itemData={data}
                outerElementType={CountriesList}
            >

                {({ index, style, data }) => (
                    <ListItem
                        style={style}
                        aria-setsize={data.length}
                        aria-posinset={index + 1}
                    >
                        {data[index]}
                    </ListItem>
                )}

            </FixedSizeList>
        </div>
    );
};