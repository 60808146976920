import { Spinner } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import {
    ButtonWithSpinnerSC, DivSpinnerBox2SC,

    DivWrapperButton2SC,
    DivWrapperInputsCustomAndButtonSC,
    DivWrapperInputsCustomSC,
} from '../styles';
import React, { FC, useEffect, useState } from 'react';
import { InputText } from './customComponents/InputText';
import { InputCheckBox } from './customComponents/InputCheckBox';
import SelectComponentBox from './SelectComponentBox';
import { IObjDepend, IPropsCustomInputs, IStateKey, IValidateData } from '../interfaces';
import CodeWrapper from './customComponents/CodeWrapper';
import { inputsCustomObj } from '../settings/inputsCustomList';
import { usePostGetDataTenantForConnector } from '../../../api/usePostGetDataTenantForConnector';

export const CustomInputs:FC<IPropsCustomInputs> = (props) => {
    const {
        connectorDetail,
        inputList,
        onChange,
        connectorDependenciesByConnectorType,
        typeProcesses
    } = props
    const { t } = useTranslation();


    const [objKey, setObjKey] = useState<IStateKey>({});
    const {
        isPending,
        mutate,
    } = usePostGetDataTenantForConnector(typeProcesses)

    useEffect(() => {
        if (connectorDetail && connectorDependenciesByConnectorType) {
            let obj: IStateKey = {}
            Object.values(connectorDependenciesByConnectorType[connectorDetail.connector_type].dependencies).forEach((item, io) => {

                item.fields.forEach((item2, i2) => {
                    obj[item2] = true
                })

            })
            setObjKey(obj)
        }

    }, [connectorDetail, connectorDependenciesByConnectorType]);

    const onClick = () => {
        if (connectorDetail) {
            let obj:IValidateData = {
                connector_type: connectorDetail.connector_type,
                form_fields: {}
            }
            Object.keys(connectorDetail).forEach((item, i) => {
                if(inputsCustomObj[item]) {
                    // @ts-ignore
                    obj.form_fields[item] = connectorDetail[item]
                }
            })
            mutate(obj)
        }
    }


    // TODO разобраться с типом
    return (
        <DivWrapperInputsCustomAndButtonSC>
            <DivWrapperInputsCustomSC>
                {
                    connectorDetail ? Object.keys(connectorDetail).map((item, i) => {

                        if (inputList[item] && connectorDependenciesByConnectorType) {
                            if (connectorDependenciesByConnectorType[connectorDetail.connector_type].dependencies[item]) {

                                let objDepend: IObjDepend = {}
                                connectorDependenciesByConnectorType[connectorDetail.connector_type].dependencies[item].fields.forEach((item2, i2) => {
                                    let string: string = ''
                                    Object.keys(connectorDetail).forEach((item3) => {
                                        if (item2 === item3) {

                                            // @ts-ignore
                                            string = connectorDetail[item3];
                                        }

                                    })
                                    objDepend[item2] = string;
                                })
                                return (
                                    <SelectComponentBox
                                        key={`sfgerwasfg${i}`}
                                        label={inputList[item].label}
                                        multiple={connectorDependenciesByConnectorType[connectorDetail.connector_type].dependencies[item].multiple ?
                                            true :
                                            false}
                                        required={objKey[inputList[item].keyName]}
                                        // @ts-ignore
                                        defaultValue={connectorDetail[item]}
                                        connectorType={connectorDetail.connector_type}
                                        dependencies={objDepend}
                                        id={connectorDetail.id ? connectorDetail.id : 0}
                                        keyName={inputList[item].keyName}
                                        onChange={onChange}
                                    />
                                )
                            }

                            else if (inputList[item].type === 'text') {

                                return(
                                    <InputText
                                        key={`sfgerwasfg${i}`}
                                        type={'text'}
                                        // @ts-ignore
                                        value={connectorDetail[item]}
                                        label={inputList[item].label}
                                        required={objKey[inputList[item].keyName]}
                                        keyName={inputList[item].keyName}
                                        onChange={onChange}
                                    />
                                )
                            }
                            else if (inputList[item].type === 'checkbox') {
                                return(
                                    <InputCheckBox
                                        key={`sfgerwasfg${i}`}

                                        // @ts-ignore
                                        checked={connectorDetail[item]}
                                        label={inputList[item].label}
                                        keyName={inputList[item].keyName}
                                        onChange={onChange}
                                    />
                                )
                            }
                            else if (inputList[item].type === 'number') {
                                return(
                                    <InputText
                                        key={`sfgerwasfg${i}`}
                                        type={'number'}
                                        // @ts-ignore
                                        value={connectorDetail[item]}
                                        label={inputList[item].label}
                                        keyName={inputList[item].keyName}
                                        onChange={onChange}
                                    />
                                )
                            }
                            else if (inputList[item].type === 'number[]') {
                                return(
                                    <InputText
                                        key={`sfgerwasfg${i}`}
                                        type={'number'}
                                        // @ts-ignore
                                        value={connectorDetail[item]}
                                        label={inputList[item].label}
                                        onChange={onChange}
                                    />
                                )
                            }
                            else if (inputList[item].type === 'code') {
                                return(
                                    <CodeWrapper
                                        key={`sfgerwasfg${i}`}
                                        label={inputList[item].label}
                                        // @ts-ignore
                                        value={connectorDetail[item]}
                                        keyName={inputList[item].keyName}
                                    />
                                )
                            }
                        }

                    }) : null
                }

            </DivWrapperInputsCustomSC>
            <DivWrapperButton2SC>
                <div></div>
                <ButtonWithSpinnerSC
                    onClick={onClick}
                    disabled={isPending}
                    appearance="primary"
                    icon={isPending ? <DivSpinnerBox2SC>
                        <Spinner size={'extra-tiny'} />
                    </DivSpinnerBox2SC>: null}
                >
                    {
                        t('Get tenant info')
                    }
                </ButtonWithSpinnerSC>

            </DivWrapperButton2SC>
        </DivWrapperInputsCustomAndButtonSC>

    );
};