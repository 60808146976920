import { DivWrapperSideSC, StyledSplit } from './styles';
import useGetTenantsListWidth from '../../hooks/useGetTenantsListWidth';
import Tools from './tools';
import { useGetTenantsAndConnectors } from '../../api/useGetTenantsConnectors';
import { TenantsPanel } from './components/TenantsPanel';
import { useEffect, useState } from 'react';
import useGetUrlParams from '../../hooks/useGetUrlParams';
import { ConnectorsList } from './components/ConnectorsList';
import { useGeneralStore } from '../../store';
import { useGetLocations } from '../../api/useGetLocations';
import { useGetConnectorDetail } from '../../api/useGetConnectorDetail';

export const Connectors = () => {
    const tenantsListWidth = useGetTenantsListWidth();
    const plId = useGetUrlParams('plId')
    const projectId = useGetUrlParams('projectId')
    useGetLocations(projectId)
    const isFetching = useGetTenantsAndConnectors(plId)
    const {
        data,
        isFetching: isFetching2
    } = useGetConnectorDetail(plId, projectId)

    const [delta, setDelta] = useState(0);
    const [deltaRightSide, setDeltaRightSide] = useState(0);
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();


    useEffect(() => {
        getDelta(tenantsListWidth)

    }, [tenantsListWidth]);

    useEffect(() => {
        updateGeneralStore({selectedProjectId: projectId ? Number(projectId) : null})
        updateGeneralStore({selectedLocationId: plId ?  Number(plId) : null})
    }, [projectId, plId]);

    const getDelta = (size: number) => {

        if (size < 31.597354088850864) {
            setDelta(94)
        }
        else if (size < 47.14187342194771) {
            setDelta(52)
        }
        else {
            setDelta(0)
        }
    }

    const getDeltaRight = (size: number) => {

        if (size < 34.3) {
            setDeltaRightSide(32)
        }
        else {
            setDeltaRightSide(0)
        }
    }

    const onDragEnd = (sizes: number[]) => {
        Tools.writeLocalstorage(sizes[0]);

        getDelta(sizes[0])
        getDeltaRight(sizes[1])
    };



    return (
        <StyledSplit
            sizes={[tenantsListWidth, 100 - tenantsListWidth]}
            onDragEnd={onDragEnd}
            gutterSize={5}
            minSize={550}
        >

            <TenantsPanel
                isShowSpinner={isFetching}
                delta={delta}
            />
            <ConnectorsList
                delta={0}
            />
        </StyledSplit>
    );
};
