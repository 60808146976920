import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import {
    IPropsModalContentCreateConnector,
    IValidateData,
} from '../interfaces';
import { useTranslation } from 'react-i18next';
import { useGeneralStore } from '../../../store';
import { BaseInputsFirstLine } from './BaseInputs';
import { Button, Divider, Spinner, Label, Input, useId, InputOnChangeData } from '@fluentui/react-components';
import { BaseInputsSecondLine } from './BaseInputs2';
import { CustomInputs } from './CustomInputs';
import { CustomSpinner } from '../../Spinner/Spinner';
import {
    DivContentDuplicateModalSC,
    DivSpinnerBox2SC, DivWrapper2ButtonSC,
    DivWrapperInputListsSC, useStylesInput,
} from '../styles';
import { SelectConnectorType } from './customComponents/SelectConnectorType';
import { inputsListBase, inputsListBase2 } from '../settings/inputsList';
import { inputsCustomObj } from '../settings/inputsCustomList';
import { usePostValidateConnectorData } from '../../../api/usePostValidateConnectorData';
import { BlockListText } from './customComponents/BlockListText';
import { v4 as uuidv4 } from 'uuid';
import { usePostCreateConnector } from '../../../api/usePostCreateConnector';
import { projectTheme } from '../../../theme';
import { EditConnectorModal } from './EditConnectorModal';
import Modals from '../../Modal/Modals';

export const CreateConnectorModal:FC<IPropsModalContentCreateConnector> = (props) => {

    const { t } = useTranslation();
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const {
        isPending,
        mutate,
        data,
    } = usePostValidateConnectorData()
    const {
        isPending: isPendingCreateConnector,
        mutate: mutateCreateConnector,
        data: dataCreateConnector,
    } = usePostCreateConnector()

    const {
        newConnector,
        messageList,
        connectorDependenciesByConnectorType,
        currentData
    } = getGeneralStore();
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isDisabledDuplicate, setIsDisabledDuplicate] = useState<boolean>(true);
    const [modalDuplicationOptions, setModalDuplicationOptions] = useState<{
        show: boolean;
    }>({
        show: false,
    });
    const [value, setValue] = useState<string>('');
    const {colorPaletteRedForeground1} = projectTheme;
    const styles = useStylesInput()
    const inputId = useId('input')


    useEffect(() => {
        if (data && !data.is_valid) {
            updateGeneralStore({
                messageList: [
                    ...messageList,
                    {
                        title: t('Validation'),
                        text: t('Not valid'),
                        uuid: uuidv4()
                    }
                ]
            })
        }

        if (data) {

            if (data.is_duplicate && currentData.permissions.includes('fsf/admin | connectors/can-create-duplicates | feature/edit')) {
                setIsDisabled(data ? !data.is_valid : true)
            }
            else if (data.is_duplicate && !currentData.permissions.includes('fsf/admin | connectors/can-create-duplicates | feature/edit')) {
                setIsDisabled(true)
            }
        }



    }, [data, currentData]);

    useEffect(() => {
        setIsDisabled(true)
    }, [newConnector]);

    const onClickValidate = () => {
        if (newConnector) {
            let obj:IValidateData = {
                connector_type: newConnector.connector_type,
                form_fields: {}
            }
            Object.keys(newConnector).forEach((item, i) => {
                if(inputsCustomObj[item]) {
                    // @ts-ignore
                    obj.form_fields[item] = newConnector[item]
                }
            })
            mutate(obj)
        }

    }

    const onChange = (keyName: string, value: string | number | boolean | null | number[]) => {

        if (newConnector) {
            updateGeneralStore({
                newConnector: {
                    ...newConnector,
                    [keyName]: value
                }
            })
        }

    }

    const onCreateConnector = () => {
        if (newConnector) {
            let obj = JSON.parse(JSON.stringify(newConnector))
            delete obj.dependencies
            mutateCreateConnector(obj)
            setModalDuplicationOptions((prevState) => {
                return {
                    ...prevState,
                    show: false,
                };
            });
        }

    }

    const onOpenModal = () => {
        setModalDuplicationOptions((prevState) => {
            return {
                ...prevState,
                show: true,
            };
        });

    }

    const hideModal = () => {
        setModalDuplicationOptions((prevState) => {
            return {
                ...prevState,
                show: false,
            };
        });
    }

    const onChangeInput = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setIsDisabledDuplicate(!(data.value === 'Create the connector'))
        setValue(data.value);
    }


    return (
        <DivWrapperInputListsSC>
            <SelectConnectorType
                onChangeStatusLoad={setIsLoad}
            />

            {
                newConnector && <>
                    <Divider />
                    <BaseInputsFirstLine
                        inputListBase={inputsListBase}
                        connectorDetail={newConnector}
                        onChange={onChange}
                    />
                    <Divider />

                    <CustomInputs
                        connectorDetail={newConnector}
                        inputList={inputsCustomObj}
                        onChange={onChange}
                        connectorDependenciesByConnectorType={connectorDependenciesByConnectorType}
                        typeProcesses={'new'}
                    />
                    <Divider />
                    <BaseInputsSecondLine
                        inputListBase={inputsListBase2}
                        connectorDetail={newConnector}
                        onChange={onChange}
                    />
                </>
            }

            <Divider />
            {
                data && data.is_duplicate && <BlockListText
                    label={t('Duplicates')}
                    data={data?.details}
                    labelColor={colorPaletteRedForeground1}
                />
            }


            <CustomSpinner text={'get connector detail'} isShow={isLoad} />
            <DivWrapper2ButtonSC>
                <Button
                    appearance="primary"
                    onClick={onClickValidate}
                    disabled={!newConnector}
                    icon={isPending ? <DivSpinnerBox2SC>
                        <Spinner size={'extra-tiny'} />
                    </DivSpinnerBox2SC> : null}
                >
                    {t('Validate')}
                </Button>
                <Button
                    appearance="primary"
                    onClick={data?.is_duplicate ? onOpenModal : onCreateConnector}
                    disabled={isDisabled}
                    icon={isPendingCreateConnector ? <DivSpinnerBox2SC>
                        <Spinner size={'extra-tiny'} />
                    </DivSpinnerBox2SC> : null}
                >
                    {t('Create')}
                </Button>
            </DivWrapper2ButtonSC>
            <Modals
                title={t('Duplicates')}
                modalOptions={modalDuplicationOptions}
                closeModal={hideModal}
            >
                <DivContentDuplicateModalSC>
                    <BlockListText
                        label={t('Duplicates')}
                        data={data ? data?.details : []}
                        labelColor={colorPaletteRedForeground1}
                    />
                    <div className={styles.root}>
                        <Label htmlFor={inputId}>
                            {t("Enter the phrase: 'Create the connector'")}
                        </Label>
                        <Input value={value} onChange={onChangeInput} id={inputId} />
                    </div>
                    <DivWrapper2ButtonSC>
                        <Button
                            appearance="primary"
                            onClick={hideModal}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={onCreateConnector}
                            disabled={isDisabledDuplicate}
                            icon={isPendingCreateConnector ? <DivSpinnerBox2SC>
                                <Spinner size={'extra-tiny'} />
                            </DivSpinnerBox2SC> : null}
                        >
                            {t('Create')}
                        </Button>
                    </DivWrapper2ButtonSC>
                </DivContentDuplicateModalSC>


            </Modals>
        </DivWrapperInputListsSC>
    );
};