import React, { FC, useEffect, useState } from 'react';
import {
    Label,
    Option,
    PopoverProps,
    Spinner,
    Tooltip,
    useId,
} from '@fluentui/react-components';
import { IPropsSelectComponent, TConnectorParams } from '../interfaces';
import { useTranslation } from 'react-i18next';
import {
    ButtonWithSpinnerSC,
    DivBoxSearchSC,
    DivSpinnerBoxSC,
    DivWrapperButton2SC,
    DivWrapperDropdownAndLabelSC,
    DivWrapperDropdownSC,
    DivWrapperListPopoverSC,
    DivWrapperListPopoverScrollSC,
} from '../styles';
import { usePostConnectorParams } from '../../../api/usePostConnectorParams';
import { ArrowClockwiseFilled } from '@fluentui/react-icons';
import { Search } from '../../Search/Search';
import { CustomPopover } from '../../CustomPopover/CustomPopover';


const SelectComponentBox: FC<IPropsSelectComponent> = (props) => {
    const {
        label,
        required = false,
        defaultValue = '',
        connectorType,
        dependencies= null,
        id,
        keyName,
        onChange
    } = props

    const selectId = useId();
    const { t } = useTranslation();
    const [dataConnectorParams, setDataConnectorParams] = useState<TConnectorParams>([{
        name: defaultValue,
        value: defaultValue
    }]);
    const [open, setOpen] = useState<boolean>(false);
    const {
        isPending,
        mutate,
        data
    } = usePostConnectorParams( id, keyName)

    useEffect(() => {
        if (data) {
            setDataConnectorParams(data)
        }
    }, [data]);



    const onClick = () => {
        mutate({
            connector_type: connectorType,
            filed_name: keyName,
            form_fields: {
                ...dependencies
            }
        })
    }


    const handleOpenChange: PopoverProps["onOpenChange"] = (e, _data) => {
        setOpen(_data.open || false);
        setDataConnectorParams(data ? data : dataConnectorParams)
    }




    const onSelectOption = (value: string) => () => {
        onChange(keyName, value ? value : '')
        setOpen(false);
    }

    const onSearchChange = (value: string) => {
        const filteredList = data?.filter((item, i) =>{
            return item.name.toLowerCase().includes(value.toLowerCase())
        })
        setDataConnectorParams(filteredList ? filteredList : [])
    };


    return (
        <DivWrapperDropdownSC>
            <DivWrapperDropdownAndLabelSC>
                <Label required={required} htmlFor={`${selectId}`}>{label}</Label>
                <CustomPopover
                    open={open}
                    onOpenChange={handleOpenChange}
                    label={defaultValue}
                >
                    <DivWrapperListPopoverSC>
                        <DivBoxSearchSC>
                            <Search
                                placeholder={`${t('Search')} ${label.toLowerCase()}`}
                                handleChange={onSearchChange}
                            />
                        </DivBoxSearchSC>
                        <DivWrapperListPopoverScrollSC>
                            {dataConnectorParams.map((option, i) => (

                                <Option onClick={onSelectOption(option.value)} value={option.value}
                                        key={`dropdown${id}-${i}`}>
                                    {option.name}
                                </Option>
                            ))}
                        </DivWrapperListPopoverScrollSC>

                    </DivWrapperListPopoverSC>
                </CustomPopover>

            </DivWrapperDropdownAndLabelSC>
            <DivWrapperButton2SC>
                <div></div>
                <Tooltip content={t('Get data')} relationship="label">
                    <ButtonWithSpinnerSC
                        appearance="primary"
                        onClick={onClick}
                        disabled={isPending}
                        icon={isPending ? <DivSpinnerBoxSC>
                            <Spinner />
                        </DivSpinnerBoxSC> : <ArrowClockwiseFilled />}
                    >

                    </ButtonWithSpinnerSC>
                </Tooltip>

            </DivWrapperButton2SC>

        </DivWrapperDropdownSC>
    );
}

export default SelectComponentBox;