import { DivWrapperSideSC, InputFilterWrapperSC } from '../styles';
import { Title } from '../../Title/Title';
import { useTranslation } from 'react-i18next';
import { useGeneralStore } from '../../../store';
import {
    CheckboxOnChangeData,
    TableColumnId,
    useFluent,
    useScrollbarWidth,
    useTableFeatures,
    useTableSelection,
    useTableSort,
} from '@fluentui/react-components';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { CustomTable } from '../../Table/CustomTable';
import { columnsConnectors } from '../settings/Columns';
import { IItemForTable } from '../../Table/interfaces';

import getItemsForTable from '../tools/getItemForTableConnector';
import { IConnector, ITenant } from '../../../types';
import Modals from '../../Modal/Modals';
import { RenderRowConnectors } from './RenderRowConnectors';
import { PanelOptionsConnectors } from './PanelOptionsConnectors';
import { EditConnectorModal } from './EditConnectorModal';
import { CreateConnectorModal } from './CreateConnectorModal';

export const ConnectorsList: FC<{ delta: number;  }> = ({ delta }) => {
    const { t } = useTranslation();
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const [searchValue, setSearchValue] = useState<string>('');
    const [isCheckedActual, setIsCheckedActual] = useState<boolean>(false);
    const { selectedTenant, connectors, selectedConnector, selectedConnectorDetail, tenants, newConnector } = getGeneralStore();
    const [connectorsForShow, setConnectorsForShow] = useState<IConnector[]>([]);
    const { targetDocument } = useFluent();
    const [heightContent, setHeightContent] = useState<number>(0);
    const scrollbarWidth = useScrollbarWidth({ targetDocument });
    const itemsConnectorTable: IItemForTable[] = getItemsForTable(connectorsForShow, searchValue);
    const [modalOptions, setModalOptions] = useState<{
        show: boolean;
    }>({
        show: false,
    });

    const [modalConnectorOptions, setModalConnectorOptions] = useState<{
        show: boolean;
    }>({
        show: false,
    });
    const [tenantForSelectedConnector, setTenantForSelectedConnector] = useState<ITenant | null>(null);

    const {
        getRows,
        selection: { toggleRow },
        sort: { getSortDirection, toggleColumnSort, sort },
    } = useTableFeatures(
        {
            columns: columnsConnectors,
            items: itemsConnectorTable,
        },
        [
            useTableSelection({
                selectionMode: 'single',
                defaultSelectedItems: new Set([0, 1]),
            }),
            useTableSort({
                defaultSortState: { sortColumn: 'name', sortDirection: 'ascending' },
            }),
        ],
    );

    useEffect(() => {
        if (!selectedTenant) {
            setConnectorsForShow(filterConnectorsActual(connectors, isCheckedActual));
        } else if (selectedTenant) {
            const connectorsFiltered = connectors.filter((item, i) => {
                return item.tenant_id === selectedTenant.id;
            });

            setConnectorsForShow(filterConnectorsActual(connectorsFiltered, isCheckedActual));
        }
    }, [selectedTenant, connectors, isCheckedActual]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        setHeightContent(window.innerHeight - (182 + delta));
    }, [delta]);

    useEffect(() => {
        setModalConnectorOptions((prevState) => {
            return {
                ...prevState,
                show: selectedConnector ? true : false,
            };
        });
    }, [selectedConnector]);

    useEffect(() => {
        if (!newConnector) {
            setModalOptions((prevState) => {
                return {
                    ...prevState,
                    show: false,
                };
            });
        }

    }, [newConnector]);

    useEffect(() => {
        if (selectedConnectorDetail && tenants.length > 0) {
            const found = tenants.find((item, i) => item.id === selectedConnectorDetail.tenant);
            setTenantForSelectedConnector(found ? found : null);
        }
    }, [selectedConnectorDetail, tenants]);

    const filterConnectorsActual = (connectors: IConnector[], checked: boolean) => {
        return connectors.filter((item, i) => (!checked ? item.isActual === !checked : true));
    };

    const onResize = (e: any) => {
        setHeightContent(e.currentTarget?.innerHeight - (182 + delta));
    };

    const searchOnChange = (value: string) => {
        setSearchValue(value);
    };

    const onCheckActual = (e: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        setIsCheckedActual(Boolean(data.checked));
    };
    const onClickItem = (id: string) => {

    };
    const rows = sort(
        getRows((row) => {
            const selected = false;
            return {
                ...row,
                onClick: (e: React.MouseEvent) => {
                    onClickItem(String(row.item.id.label));
                    toggleRow(e, row.rowId);
                },
                onKeyDown: (e: React.KeyboardEvent) => {
                    if (e.key === ' ') {
                        e.preventDefault();
                        toggleRow(e, row.rowId);
                    }
                },
                selected,
                appearance: selected ? ('brand' as const) : ('none' as const),
            };
        }),
    );
    const headerSortProps = (columnId: TableColumnId) => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });
    const createStylesPres = () => {
        return {
            width: heightContent < rows.length * 45 ? scrollbarWidth : 0,
        };
    };

    const closeModal = () => {
        setModalOptions((prevState) => ({
            ...prevState,
            show: false,
        }));
        updateGeneralStore({ newConnector: null });
    };

    const closeModalEdit = () => {
        updateGeneralStore({
            selectedConnector: null ,
            selectedConnectorDetail: null
        });
    };

    const showModal = () => {
        setModalOptions((prevState) => ({
            ...prevState,
            show: true,
        }));
    };


    return (
        <DivWrapperSideSC>
            <Title>{selectedTenant ? `${t('Connectors for')} ${selectedTenant.name}` : t('Connectors')}</Title>
            <InputFilterWrapperSC>
                <PanelOptionsConnectors
                    searchOnChange={searchOnChange}
                    isCheckedActual={isCheckedActual}
                    onCheckActual={onCheckActual}
                    showModal={showModal}
                    disabledButton={!selectedTenant}
                />
            </InputFilterWrapperSC>
            <CustomTable
                columns={columnsConnectors}
                rows={rows}
                headerSortProps={headerSortProps}
                style={createStylesPres()}
                height={heightContent}
                iItemProjectsForTables={itemsConnectorTable}
                RenderRowInner={RenderRowConnectors}
            />
            <Modals title={`${t('Create new connector')}, ${t('tenant')}: ${selectedTenant?.name}(${selectedTenant?.id})`} modalOptions={modalOptions} closeModal={closeModal}>
                <CreateConnectorModal
                    tenant={selectedTenant}
                />
            </Modals>
            <Modals
                title={`${t('Edit connector')} ${selectedConnector?.id}, ${t('type')}: ${
                    selectedConnector?.connector_type
                }, ${t('tenant')}: ${tenantForSelectedConnector?.name}(${selectedConnectorDetail?.tenant})`}
                modalOptions={modalConnectorOptions}
                closeModal={closeModalEdit}
            >
                <EditConnectorModal
                    connectorId={selectedConnector?.id}
                    connectorType={selectedConnector?.connector_type}
                />
            </Modals>
        </DivWrapperSideSC>
    );
};
