import { DivWrapperLocationsModal, InputFilterWrapperSC } from './styles';
import { Search } from '../Search/Search';
import { useTranslation } from 'react-i18next';
import { useGetAllLocations } from '../../api/useGetAllLocations';
import { useGeneralStore } from '../../store';
import { CustomTable } from '../Table/CustomTable';
import columns from './settings/columns';
import {
    TableColumnId,
    useFluent,
    useScrollbarWidth,
    useTableFeatures,
    useTableSelection,
    useTableSort,
} from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { IItemForTable } from '../Table/interfaces';
import getItemsForTable from './tools/getItemsForTable';
import { RenderRow } from './components/RenderRow';

export const LocationsModalContent = () => {
    const { t } = useTranslation();
    useGetAllLocations();
    const { getGeneralStore } = useGeneralStore();
    const { allLocations } = getGeneralStore();
    const [searchValue, setSearchValue] = useState<string>('');
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });
    const [heightContent, setHeightContent] = useState<number>(0);

    const itemsLocationTable: IItemForTable[] = getItemsForTable(allLocations, searchValue);

    const {
        getRows,
        selection: { toggleRow },
        sort: { getSortDirection, toggleColumnSort, sort },
    } = useTableFeatures(
        {
            columns,
            items: itemsLocationTable,
        },
        [
            useTableSelection({
                selectionMode: 'single',
                defaultSelectedItems: new Set([0, 1]),
            }),
            useTableSort({
                defaultSortState: { sortColumn: 'name', sortDirection: 'ascending' },
            }),
        ],
    );
    useEffect(() => {
        window.addEventListener('resize', onResize);
        setHeightContent(window.innerHeight - 275);
    }, []);

    const onResize = (e: any) => {
        setHeightContent(e.currentTarget?.innerHeight - 275);
    };
    const searchOnChange = (value: string) => {
        setSearchValue(value);
    };

    const rows = sort(
        getRows((row) => {
            const selected = false;
            return {
                ...row,
                onClick: (e: React.MouseEvent) => {
                    // onClickItem(String(row.item.id.label))
                    toggleRow(e, row.rowId);
                },
                onKeyDown: (e: React.KeyboardEvent) => {
                    if (e.key === ' ') {
                        e.preventDefault();
                        toggleRow(e, row.rowId);
                    }
                },
                selected,
                appearance: selected ? ('brand' as const) : ('none' as const),
            };
        }),
    );

    const createStylesPres = () => {
        return {
            width: heightContent < rows.length * 45 ? scrollbarWidth : 0,
        };
    };

    const headerSortProps = (columnId: TableColumnId) => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });

    return (
        <DivWrapperLocationsModal>
            <InputFilterWrapperSC>
                <Search placeholder={t('Search')} handleChange={searchOnChange} />
            </InputFilterWrapperSC>
            <CustomTable
                columns={columns}
                rows={rows}
                headerSortProps={headerSortProps}
                style={createStylesPres()}
                height={heightContent}
                iItemProjectsForTables={itemsLocationTable}
                RenderRowInner={RenderRow}
            />
        </DivWrapperLocationsModal>
    );
};
