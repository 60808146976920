import { DivWrapperInputsBaseSC } from '../styles';
import React, { FC } from 'react';
import { InputText } from './customComponents/InputText';
import { InputCheckBox } from './customComponents/InputCheckBox';
import CalendarBox from './customComponents/CalendarBox';
import CheckboxList from './customComponents/CheckboxList';
import { IPropsBaseInputFirstLine } from '../interfaces';

export const BaseInputsFirstLine: FC<IPropsBaseInputFirstLine> = (props) => {
    const {
        connectorDetail,
        inputListBase,
        onChange
    } = props


    // TODO разобраться с типом
    return (
        <DivWrapperInputsBaseSC>
            {
                connectorDetail ? inputListBase.map((item, i) => {

                    if (item.type === 'text') {

                        return(
                            <InputText
                                key={`sfgerwasfg${i}`}
                                type={'text'}
                                // @ts-ignore
                                value={connectorDetail[item.keyName]}
                                label={item.label}
                                keyName={item.keyName}
                                onChange={onChange}
                            />
                        )
                    }
                    else if (item.type === 'checkbox') {
                        return(
                            <InputCheckBox
                                key={`sfgerwasfg${i}`}

                                // @ts-ignore
                                checked={connectorDetail[item.keyName]}
                                label={item.label}
                                keyName={item.keyName}
                                onChange={onChange}
                            />
                        )
                    }
                    else if (item.type === 'number') {
                        return(
                            <InputText
                                key={`sfgerwasfg${i}`}
                                type={'number'}
                                // @ts-ignore
                                value={connectorDetail[item.keyName]}
                                label={item.label}
                                keyName={item.keyName}
                                onChange={onChange}
                            />
                        )
                    }
                    else if (item.type === 'timesList') {
                        return(
                            <CheckboxList
                                key={`sfgerwasfg${i}`}
                                // @ts-ignore
                                values={connectorDetail[item.keyName]}
                                label={item.label}
                                keyName={item.keyName}
                                onChange={onChange}
                                connectorDetail={connectorDetail}
                            />
                        )
                    }
                    else if (item.type === 'date') {
                        return(
                            <CalendarBox
                                key={`sfgerwasfg${i}`}
                                label={item.label}
                                dateG={item.keyName === 'date_to' ? connectorDetail.date_from ? connectorDetail.date_from
                                    : '' : connectorDetail.date_to ? connectorDetail.date_to : ''}

                                // @ts-ignore
                                value={connectorDetail[item.keyName]}
                                keyName={item.keyName}
                                onChange={onChange}
                            />
                        )
                    }
                }) : null
            }
        </DivWrapperInputsBaseSC>
    );
};