import React, { FC, useEffect, useState } from 'react';
import { IPropsDisplayList } from '../../interfaces';
import { FixedSizeList } from "react-window";
import { List, ListItem } from "@fluentui/react-list-preview";
import { Label } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

const CountriesList = React.forwardRef<HTMLUListElement>(
    (props: React.ComponentProps<typeof List>, ref) => (
        <List navigationMode="items" aria-label="Countries" {...props} ref={ref} />
    )
);

const DisplayList:FC<IPropsDisplayList> = (props) => {
    const {
        list
    } = props
    const [listPoints, setListPoints] = useState<string[]>([]);
    const { t } = useTranslation();

    useEffect(() => {

        const _list = list.map((item, i) => {
            return `${item.name} - (KKTreg:${item.kkt_reg_number} KKT:${item.kkt_factory_number} FN:${item.fn_factory_number})`
        })
        setListPoints(_list)
    }, [list]);


    return (
        <div>
            <Label size="large">
                {t('Points of sale')}
            </Label>
            <FixedSizeList
                height={100}
                itemCount={listPoints.length}
                itemSize={20}
                width="100%"
                itemData={listPoints}
                outerElementType={CountriesList}
            >

                {({ index, style, data }) => (
                    <ListItem
                        style={style}
                        aria-setsize={listPoints.length}
                        aria-posinset={index + 1}
                    >
                        {data[index]}
                    </ListItem>
                )}

            </FixedSizeList>
        </div>
    );
}

export default DisplayList;